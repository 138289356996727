import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { Icon } from '@iconify/react';
import { useEmailNotificationsEnabled, useRetrieveNotificationEmailStatus } from '../../../hooks/useNotificationHooks';
import useUserStore from '../../../store/useUserStore';
import Toggle from '../../../components/Button/Toggle';
import './SettingsNotifications.css';
import './Settings.css';

const SettingsNotifications = () => {
  const [isAllNotificationsOn, setAllNotificationsOn] = useState(false);
  const [notificationsState, setNotificationsState] = useState({
    taskAssigned: false,
    taskRejected: false,
    taskStatusChange: false,
    sharedTemplate: false,
    sharedApplication: false,
    sharedDocument: false,
  });
  const { userId } = useUserStore();
  const { mutate: emailNotificationEnabledMutate } = useEmailNotificationsEnabled();
  const { data: notificationEmailStatus } = useRetrieveNotificationEmailStatus();
  // console.log('notificationEmailStatus', notificationEmailStatus);

  const toggleEmailsData = [
    {
      id: nanoid(),
      toggleTitle: 'All Notifications',
      toggleDesc: 'Receive all notifications.',
      state: isAllNotificationsOn,
      onChange: (e) => handleToggleAll(e.target.checked),
    },
    {
      id: nanoid(),
      toggleTitle: 'Task Assigned Notifications',
      toggleDesc: 'Receive notifications when tasks are assigned to you.',
      state: notificationsState.taskAssigned,
      onChange: (e) => handleToggleIndividual('taskAssigned', e.target.checked),
    },
    {
      id: nanoid(),
      toggleTitle: 'Task Rejected Notification',
      toggleDesc: 'Receive notifications when tasks are rejected.',
      state: notificationsState.taskRejected,
      onChange: (e) => handleToggleIndividual('taskRejected', e.target.checked),
    },
    {
      id: nanoid(),
      toggleTitle: 'Task Status Notification',
      toggleDesc: 'Receive notifications when tasks status changes.',
      state: notificationsState.taskStatusChange,
      onChange: (e) => handleToggleIndividual('taskStatusChange', e.target.checked),
    },
    {
      id: nanoid(),
      toggleTitle: 'Shared Templates Notification',
      toggleDesc: 'Receive notifications when templates are shared with you.',
      state: notificationsState.sharedTemplate,
      onChange: (e) => handleToggleIndividual('sharedTemplate', e.target.checked),
    },
    {
      id: nanoid(),
      toggleTitle: 'Shared Application Notification',
      toggleDesc: 'Receive notifications when applications are shared with you.',
      state: notificationsState.sharedApplication,
      onChange: (e) => handleToggleIndividual('sharedApplication', e.target.checked),
    },
    {
      id: nanoid(),
      toggleTitle: 'Shared Document Notification',
      toggleDesc: 'Receive notifications when documents are shared with you.',
      state: notificationsState.sharedDocument,
      onChange: (e) => handleToggleIndividual('sharedDocument', e.target.checked),
    },
  ];

  const navigate = useNavigate();

  // save toggle
  useEffect(() => {
    if (notificationEmailStatus) {
      // to use db column names
      const {
        emailTaskAssignedEnabled,
        emailTaskRejectedEnabled,
        emailTaskStatusChangeEnabled,
        emailSharedTemplateEnabled,
        emailSharedApplicationEnabled,
        emailSharedDocumentEnabled,
      } = notificationEmailStatus.data;
      // console.log('notificationEmailStatus.data', notificationEmailStatus.data);

      // all notification status
      const allNotificationsEnabled =
        emailTaskAssignedEnabled &&
        emailTaskRejectedEnabled &&
        emailTaskStatusChangeEnabled &&
        emailSharedTemplateEnabled &&
        emailSharedApplicationEnabled &&
        emailSharedDocumentEnabled;
      setAllNotificationsOn(allNotificationsEnabled);
      // console.log('allNotificationsOn', isAllNotificationsOn);

      // each notification status
      setNotificationsState({
        taskAssigned: emailTaskAssignedEnabled,
        taskRejected: emailTaskRejectedEnabled,
        taskStatusChange: emailTaskStatusChangeEnabled,
        sharedTemplate: emailSharedTemplateEnabled,
        sharedApplication: emailSharedApplicationEnabled,
        sharedDocument: emailSharedDocumentEnabled,
      });
      // console.log('notificationsState', notificationsState);
    }
  }, [notificationEmailStatus]);

  // toggle
  const handleToggleAll = (value) => {
    emailNotificationEnabledMutate({
      userId,
      notificationSettings: {
        taskAssigned: value,
        taskRejected: value,
        taskStatusChange: value,
        sharedTemplate: value,
        sharedApplication: value,
        sharedDocument: value,
      },
    });
    // console.log('All notification value', value);
  };

  const handleToggleIndividual = (field, value) => {
    emailNotificationEnabledMutate({
      userId,
      notificationSettings: {
        ...notificationsState,
        [field]: value,
      },
    });
    // console.log('Individuals notifications value', value);
    // console.log('field', field);
  };

  return (
    <div className="notifications-settings-container balance-settings-container">
      <div className="balance-settings-back-button-container" onClick={() => navigate('/Hub/Settings')}>
        <Icon icon="ic:sharp-arrow-back-ios" className="balance-settings-back-button" />
      </div>
      <div className="notifications-settings-title-container">
        <h1 className="balance-settings-title">Notifications</h1>
        <p className="balance-settings-description">Manage your notifications settings.</p>
        {/* line */}
        <div className="balance-settings-line"></div>
      </div>
      {/* contents */}
      <div className="notifications-settings-contents-container">
        <div className="notifications-settings-contents-emails-container">
          <div className="notifiacations-settings-contents-emails-left-container">
            <h1 className="notifications-settings-contents-emails-title">Email Notifications</h1>
            <p className="notifications-settings-contents-emails-desc">
              Get emails to find out what's going on when you're not online. You can turn these off.
            </p>
          </div>
          <div className="notifications-settings-contents-emails-right-container">
            {toggleEmailsData.map((data, index) => (
              <div key={data.id} className="notifications-settings-contents-emails-toggle-container">
                <Toggle onChange={data.onChange} state={data.state} />
                <div className="notifications-settings-contents-emails-toggle-inner-contianer">
                  <h3 className="notifications-settings-contents-emails-toggle-title">{data.toggleTitle}</h3>
                  <p className="notifications-settings-contents-emails-toggle-desc">{data.toggleDesc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsNotifications;
