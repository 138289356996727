import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import ServerClient from '../services/api-client';
import useNotificationStore from '../store/useNotificationStore';
import useUserStore from '../store/useUserStore';
import AssignTask from '../pages/Hub/Tasks/AssignTask';

// retrieve notifications
export function useRetrieveNotifications() {
  const { userId } = useUserStore();
  const setNotifications = useNotificationStore((state) => state.setNotifications);

  return useQuery({
    queryKey: ['retrieveNotifications'],
    queryFn: async () => {
      const serverClient = new ServerClient('/api/retrieveNotifications');
      const res = await serverClient.post({ userId: userId });
      return res.data;
    },
    onSuccess: (data) => {
      console.log('retrieveNotifications', data);
      setNotifications(data);
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    },
  });
}

// mark notifications as read
export function useMarkNotificationsAsRead() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ userId, notificationId }) => {
      const serverClient = new ServerClient('/api/markNotificationAsRead');
      const res = await serverClient.post({ userId, notificationId });
      return res.data;
    },
    onSuccess: (data) => {
      console.log('Notification marked as read:', data);
      queryClient.invalidateQueries(['retrieveNotifications']);
    },
    onError: (error) => {
      console.error('Error mark notification as read:', error);
    },
  });
}

// settings notification toggle
export function useEmailNotificationsEnabled() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ userId, notificationSettings }) => {
      const serverClient = new ServerClient('/api/db/emailNotificationsEnabled');
      const res = await serverClient.post({
        userId,
        notificationSettings,
      });
      return res.data;
    },
    onSuccess: () => {
      console.log('useEmailNotificationsEnabled hooks OK.');
      queryClient.invalidateQueries(['retrieveNotificationEmailStatus']);
    },
    onError: (error) => {
      console.error('Error useEmailNotificationsEnabled hooks.', error);
    },
  });
}


// notifications send via email
export function useNotificationsByEmail() {
  return useMutation({
    mutationFn: async ({ userName, assignedToId, image, actionType }) => {
      const serverClient = new ServerClient('/api/db/notificationsByEmail');
      const res = await serverClient.post({ userName, assignedToId, image, actionType});
      return { data: res.data };
    },
    onSuccess: () => {
      console.log('useNotificationsByEmail hooks OK.');
    },
    onError: (error) => {
      console.error('Error useNotificationsByEmail hooks:', error);
    },
  });
}

// retrieve notification email status
export function useRetrieveNotificationEmailStatus() {
  const { userId } = useUserStore();

  return useQuery({
    queryKey: ['retrieveNotificationEmailStatus', userId],
    queryFn: async () => {
      const serverClient = new ServerClient('/api/db/notificationEmailStatus');
      const res = await serverClient.post({ userId });

      return res.data.emailNotificationsEnabled || res.data;
    },
    onError: (error) => {
      console.error('Error useRetrieveNotificationEmailStatus', error);
    },
  });
}
