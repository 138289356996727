import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { useGetMyTasks, useGetAssignedTasks, useRetrieveArchivedTasks, useDeleteTasks } from '../../../hooks/useTaskHooks';
import { useChangeStatus } from '../../../hooks/useClientHooks';
import { useNotificationsByEmail } from '../../../hooks/useNotificationHooks';
import { useSocket } from '../../../SocketContext';
import useUserStore from '../../../store/useUserStore';
import useTaskStore from '../../../store/useTaskStore';
import useModalStore from '../../../store/useModalStore';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Popup/Modal';
import RejectionPopup from '../../../components/Popup/RejectionPopup';
import Loading from '../../../components/Loading/Loading';
import Error from '../../../components/Loading/Error';
import './ViewTask.css';
import LeftArrow from '../../../assets/Icon/Arrow_Left_Dark.svg';

const ViewTask = () => {
  const [filteredTaskData, setFilteredTaskData] = useState([]);
  const [isRejectionModalOpen, setRejectionModalOpen] = useState(false);
  const [invalidText, setInvalidText] = useState('');
  const [showButton, setShowButton] = useState(false);
  const [isStatusUpdated, setStatusUpdated] = useState(false);

  // hooks
  const { data: myTaskData, isLoading: myTaskLoading, isError: myTaskError } = useGetMyTasks();
  const { data: assignedData } = useGetAssignedTasks();
  const { data: archivedData } = useRetrieveArchivedTasks();
  const { mutate: deleteTasksMutate } = useDeleteTasks();
  const { mutate: changeStatusMutate } = useChangeStatus();
  const { mutate: notificationsByEmail } = useNotificationsByEmail(); // notifications via email

  // store
  const { userName, taskData } = useUserStore();
  const { selectedTaskId, textarea, setTextArea, selectedStatusTab } = useTaskStore();
  const { selectedStatus, setSelectedStatus } = useModalStore();

  const { socket } = useSocket();
  const navigate = useNavigate();

  useEffect(() => {
    let data = [];

    if (selectedStatusTab === 'My Tasks') {
      data = myTaskData || [];
    } else if (selectedStatusTab === 'Assigned') {
      data = assignedData || [];
    } else if (selectedStatusTab === 'Archived') {
      data = archivedData || [];
    }

    const filteredData = data.filter((task) => selectedTaskId.includes(task.uniqueId));
    setFilteredTaskData(Array.isArray(filteredData) ? filteredData : []);
  }, [selectedStatusTab, myTaskData, assignedData, archivedData, selectedTaskId]);

  if (myTaskLoading) {
    return <Loading />;
  }
  if (myTaskError) {
    return <Error />;
  }

  // reject task
  const openRejectionModal = () => {
    setRejectionModalOpen(true);
  };
  const closeRejectionModal = () => {
    setTextArea('');
    setInvalidText('');
    setRejectionModalOpen(false);
  };
  const handleChangeText = (e) => {
    setTextArea(e.target.value);
    setInvalidText('');
  };
  // confirm reject task
  const onConfirmReject = async () => {
    try {
      const assignedDate = new Date().toISOString();
      const actionType = 'rejectTask';

      if (!textarea) {
        setInvalidText('Description required.');
        return;
      }

      // notification
      const notification = {
        userAssignedId: filteredTaskData[0].userId,
        senderName: userName,
        assignedDate: assignedDate,
        desc: textarea,
      };
      console.log('socket', socket);
      socket.emit('taskRejected', notification);

      const uuid = filteredTaskData[0]?.uniqueId;
      if (!uuid) {
        console.error('UUID not found.');
        return;
      }

      const imagePath = '/Logo.png';

      const response = await fetch(imagePath);
      if (!response.ok) {
        throw new Error('Failed to fetch image.');
      }

      const blob = await response.blob();

      if (blob.type.startsWith('text')) {
        throw new Error('Fetched file is not an image.');
      }

      const reader = new FileReader();
      reader.onloadend = async () => {
        const imageData = reader.result;

        // delete task
        deleteTasksMutate(
          { uuid },
          {
            onSuccess: () => {
              navigate('/Hub/Tasks#MyTasks');

              notificationsByEmail(
                { userName, assignedToId: filteredTaskData[0].userId, image: imageData, actionType },
                {
                  onSuccess: () => {
                    console.log('Notification email sent successfully.');
                  },
                  onError: (error) => {
                    console.error('Error sending notification email:', error);
                  },
                }
              );
            },
            onError: (error) => {
              console.error('Error deleting task:', error);
            },
          }
        );
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.log('Error in onConfirmReject:', error);
    }
  };

  // status change
  const tasksOptions = [
    { value: 'Complete', label: 'Complete' },
    { value: 'In-progress', label: 'In-progress' },
    { value: 'Incomplete', label: 'Incomplete' },
  ];
  // do not include current status in dropdown
  const currentTask = filteredTaskData[0];
  const currentStatus = currentTask?.status;
  const filteredTasksOptions = tasksOptions.filter((option) => option.value !== currentStatus);

  // console.log('currentTask', currentTask);

  const handleChangeDropdown = (selectedOption) => {
    setSelectedStatus(selectedOption);
    // console.log('selectedOption', selectedOption);
    setShowButton(true);
    setStatusUpdated(false);
  };

  // status change action
  const onChangeStatus = async () => {
    const assignedDate = new Date().toISOString();

    if (!selectedStatus || !selectedStatus.value) {
      console.error('No status selected.');
      return;
    }

    const newStatus = {
      uniqueId: currentTask?.uniqueId,
      applicationStatus: selectedStatus.value,
    };

    changeStatusMutate(newStatus, {
      onSuccess: () => {
        console.log('Status change action done.');

        const task = taskData?.find((task) => task.uniqueId === currentTask?.uniqueId);
        if (!task) {
          console.error('Task not found.');
          return;
        }

        // archived tab? is not included
        let receiverId;
        if (selectedStatusTab === 'Assigned') {
          receiverId = task?.assignedToId;
        } else {
          receiverId = task?.userId;
        }
        console.log('receiverId', receiverId);

        const statusChangeNotification = {
          receiverId: receiverId,
          senderName: userName,
          uniqueId: currentTask?.uniqueId,
          applicationStatus: selectedStatus.value,
          assignedDate: assignedDate,
          message: `${userName} changed a status in your application.`,
        };
        console.log('socket', socket);
        socket.emit('statusChange', statusChangeNotification);
        setStatusUpdated(true);
        setShowButton(false);
      },
      onError: (error) => {
        console.error('Error changing status:', error);
      },
    });
  };

  const handleClickBackButton = () => {
    if (selectedStatusTab === 'My Tasks') {
      navigate('/Hub/Tasks#MyTasks');
    } else if (selectedStatusTab === 'Assigned') {
      navigate('/Hub/Tasks#Assigned');
    } else if (selectedStatusTab === 'Archived') {
      navigate('/Hub/Tasks#Archived');
    }
  };

  // title change
  const onTitleChange = {
    'My Tasks': 'My Task',
    Assigned: 'Assigned Task',
    Archived: 'Archived Task',
  };
  const title = onTitleChange[selectedStatusTab] || 'Task';

  return (
    <div className="balance-task-container">
      <div className="balance-task-title-container">
        <img src={LeftArrow} alt="ArrowIcon" className="balance-task-arrow-icon" onClick={handleClickBackButton} />
        <h1 className="balance-global-title">{title}</h1>
        {selectedStatusTab === 'My Tasks' && (
          <Button
            buttonName="Reject"
            className="balance-reject-button balance-global-movable-button"
            onClick={openRejectionModal}
          />
        )}
      </div>
      {(selectedStatusTab === 'My Tasks' || selectedStatusTab === 'Assigned' || selectedStatusTab === 'Archived') && (
        <div className="balance-view-task-inner-container">
          {filteredTaskData?.map((task) => (
            <div key={task.uniqueId} className="balance-view-task-container">
              {/* status */}
              <div className="balance-task-details-container">
                <p className="balance-assign-task-label">Status</p>
                <div style={{ marginTop: '-10px' }}>
                  <Select
                    id="status"
                    value={
                      filteredTasksOptions.find((option) => option.value === selectedStatus?.value) || {
                        label: currentStatus,
                        value: currentStatus,
                      }
                    }
                    options={filteredTasksOptions}
                    className="balance-view-task-dropdown"
                    onChange={handleChangeDropdown}
                  />
                </div>
                {/* <p className={`balance-task-details ${getTypeStyle(task.status)}`}>{task.status}</p> */}
              </div>
              {/* assigned date */}
              <div className="balance-task-details-container">
                <p className="balance-assign-task-label">Assigned date</p>
                <p className="balance-task-details">
                  {new Date(task.assignedDate).toLocaleDateString('en-US', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                </p>
                {/* <p className="balance-task-details">{new Date(task.assignedDate).toLocaleDateString()}</p> */}
              </div>
              {/* assignee */}
              <div className="balance-task-details-container">
                <p className="balance-assign-task-label">Assigned by</p>
                <p className="balance-task-details">{task.userName}</p>
              </div>
              {/* client name */}
              <div className="balance-task-details-container">
                <p className="balance-assign-task-label">Client name</p>
                <p className="balance-task-details">{task.client}</p>
              </div>
              {/* application name */}
              <div className="balance-task-details-container">
                <p className="balance-assign-task-label">Application name</p>
                <p className="balance-task-details">{task.applicationName}</p>
              </div>
              <div className="balance-task-details-container">
                <p className="balance-assign-task-label">Description</p>
                <p className="balance-task-details" value={textarea}>
                  {task.description}
                </p>
              </div>
              {showButton && (
                <>
                  <hr className="balance-task-confirm-button-line" />
                  <Button buttonName="Confirm" className="balance-task-confirm-button" onClick={onChangeStatus} />
                </>
              )}
              {isStatusUpdated && <p className="balance-view-task-status-update">Your status is updated.</p>}
            </div>
          ))}
        </div>
      )}
      {isRejectionModalOpen && (
        <Modal width="40rem" onClose={closeRejectionModal}>
          <RejectionPopup
            onClose={closeRejectionModal}
            onConfirm={onConfirmReject}
            invalidText={invalidText}
            checkConfirm="Please provide a reason for rejection."
            handleChangeText={handleChangeText}
          />
        </Modal>
      )}
    </div>
  );
};

export default ViewTask;
